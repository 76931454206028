import styled from 'styled-components'
import { primaryColor, bodyFont, xl, lg, md } from 'config/variables'

export const AppoverviewsecStyles = styled.div`
 position: relative;
  padding-bottom: 100px;
  margin-bottom: 126px;
  ${xl(`
      margin-bottom: 100px;
      padding-bottom: 80px;
  `)} 
   ${lg(`
      margin-bottom: 100px;
      padding-bottom: 80px;
  `)} 
  ${md(`
      margin-bottom: 40px;
      padding-bottom: 40px;
  `)} 
  &:after {
    content: '';
    width: 100%;
    height: 827px;
    background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f7f7f7);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .container {max-width:1020px; width:100%;}
  .appOverviewScreenRow {
      display:flex;
      flex-wrap:wrap;
      ${md(`
        flex-wrap:nowrap;
        overflow: auto;
     `)} 
      .appOverviewScreenItem {
          flex:0 1 50%;
          margin-bottom:80px;
          ${md(`
               flex:0 0 290px;
               padding:0 10px;
               margin-bottom:0;
            `)} 
          .appOverviewScreenBox {
            max-width: 370px;
            margin:0 0 0 auto;
            display: flex;
            flex-direction: column;
            ${lg(`
              max-width: 300px;
            `)} 
            .appOverviewScreenImage {
                ${lg(`
                    order:2;
                `)} 
            }
            .appOverviewScreenDetails {
                display:flex;
                align-items:flex-start;
                flex-direction:column;
                justify-content:center;
                min-height:180px;
                ${lg(`
                    min-height:auto;
                    margin-bottom:30px;
                    order:1;
                `)} 
                h4 {
                    font-size: 18px;
                    font-weight: 600;
                    color: ${primaryColor};
                    margin:0 0 4px;
                    line-height:30px;
                }
                p {
                    font-size: 15px;
                    line-height:20px;
                    letter-spacing: -0.15px;
                    margin:0px;
                }
                
            }
          }
          &:nth-child(2n + 1) {
            .appOverviewScreenBox {
                margin:0 auto 0 0;
              .appOverviewScreenImage {
                  order:2;
              }
              .appOverviewScreenDetails {
                  order:1;
              }
            }
          }
          &:last-child, &:nth-last-child(2) {
              margin-bottom:0px;
          }
      }
  }
`
