import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { lg, md, xl } from 'config/variables'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { FillInRxProps } from 'components/Props/fill-in-rx'
import Casebanner from 'components/Casebanner/Casebanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Interactiveappsec from 'components/Interactiveappsec/Interactiveappsec'
import Caseresultsec from 'components/Caseresultsec/Caseresultsec'
import Appoverviewsec from 'components/Appoverviewsec/Appoverviewsec'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Reliabilityinstancessec from 'components/Reliabilityinstancessec/Reliabilityinstancessec'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'

export const FillInRxPage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #e4eaef;
          }
        }
      }
    }
  }
  .getHiredSec {
    .appoveRviewSec {
      padding-bottom: 0;
      &:after {
        display: none;
      }
    }
  }
  .Casecontentfullwimg {
    figure {
      margin: 54px 0 70px;
      ${lg(`
            margin: 50px 0 40px;
        `)}
      ${md(`
            margin: 30px 0 20px;
        `)}
    }
  }
`

const FillInRx = memo(props => {
  const { data } = props
  const { location } = props

  let Items = [
    props.data.appOverviewScreen1.childImageSharp.fluid,
    props.data.appOverviewScreen2.childImageSharp.fluid,
    props.data.appOverviewScreen3.childImageSharp.fluid,
    props.data.appOverviewScreen4.childImageSharp.fluid,
  ]
  let getHiresImg = [
    props.data.getHiresScreen1.childImageSharp.fluid,
    props.data.getHiresScreen2.childImageSharp.fluid,
    props.data.getHiresScreen3.childImageSharp.fluid,
    props.data.getHiresScreen4.childImageSharp.fluid,
  ]
  const itemfwidth = props.data.techArchitechDia.childImageSharp.fluid
  let crowdTest = [props.data.mixpanel.childImageSharp.fluid]
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })
  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Fill In Rx"
        description=""
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <FillInRxPage>
        <Casebanner
          bannerdata={FillInRxProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={FillInRxProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Interactiveappsec Interactiveappdata={FillInRxProps} {...props} />
        </div>
        {showContent && (
          <>
            <Caseresultsec
              caseresultsecdata={FillInRxProps}
              {...props}
              showImages={false}
            />
            <Appoverviewsec
              appOverviewSecData={FillInRxProps.appOverviewSecProps}
              {...props}
              showImages={false}
              screenImg={Items}
            />
            <div className="getHiredSec">
              <Appoverviewsec
                appOverviewSecData={FillInRxProps.getHiredSecProps}
                {...props}
                showImages={false}
                screenImg={getHiresImg}
              />
            </div>
            <Casecontentfullwimg
              casecontentfullwdata={FillInRxProps.casecontentfullwProps}
              fluid={itemfwidth}
            />
            <Reliabilityinstancessec
              ReliabilityinstancesData={FillInRxProps.ReliabilityinstancesProps}
              {...props}
              fluid={crowdTest}
            />
            <Relatedcaselist relatedcaselistdata={FillInRxProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={FillInRxProps} />
          </>
        )}
      </FillInRxPage>
    </Layout>
  )
})
export default FillInRx

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/fill-in-rx-banner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    InteractiveappImages: file(
      relativePath: { regex: "/agile-product-diagram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1190) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen1: file(relativePath: { regex: "/swifts1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen2: file(relativePath: { regex: "/swifts2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen3: file(relativePath: { regex: "/swifts3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen4: file(relativePath: { regex: "/swifts4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen5: file(relativePath: { regex: "/swifts5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen6: file(relativePath: { regex: "/swifts6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen7: file(relativePath: { regex: "/swifts7@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen8: file(relativePath: { regex: "/swifts8@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen9: file(relativePath: { regex: "/swifts9@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseFullimages: file(
      relativePath: { regex: "/fih-related-case-studies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    appOverviewScreen1: file(
      relativePath: { regex: "/fillinrx-post-a-job-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 370) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appOverviewScreen2: file(
      relativePath: { regex: "/fillinrx-review-app-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 370) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appOverviewScreen3: file(
      relativePath: { regex: "/fillinrx-notification-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 370) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appOverviewScreen4: file(
      relativePath: { regex: "/fill-in-rx-leave-rating-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 370) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    getHiresScreen1: file(
      relativePath: { regex: "/fillibrx-set-preferences-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 370) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    getHiresScreen2: file(
      relativePath: { regex: "/fillinrx-own-boss-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 370) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    getHiresScreen3: file(
      relativePath: { regex: "/fillinrx-reminded-shift-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 370) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    getHiresScreen4: file(
      relativePath: { regex: "/fillinrx-affairs-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 370) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    techArchitechDia: file(
      relativePath: { regex: "/algorithm-image@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel: file(relativePath: { regex: "/applause-product@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/waypoint-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/ona-dating-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
