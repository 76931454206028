import React from 'react'

import { AppoverviewsecStyles } from './Appoverviewsec.styles'
import Image from 'components/ImgOptimized'

function Appoverviewsec(props) {
  const { appOverviewSecData } = props
  return (
    <AppoverviewsecStyles className="appoveRviewSec">
      <div className="container">
        <div className="AppoverviewsecTitle">
          <h2>{appOverviewSecData.heading}</h2>
        </div>
        <div className="appOverviewScreenRow">
          {appOverviewSecData.appOverviewSecDetails.map(
            (appOverviewSecProps, i) => {
              return (
                <div key={i} className="appOverviewScreenItem">
                  <div className="appOverviewScreenBox">
                    <div className="appOverviewScreenImage">
                      <Image
                        fluid={props.screenImg[i]}
                        lazyload={{
                          enabled: true,
                          once: true,
                          offset: 1000,
                          heigth: 411,
                        }}
                      />
                    </div>
                    <div className="appOverviewScreenDetails">
                      <h4>{appOverviewSecProps.appOverviewScreenTitle}</h4>
                      <p>{appOverviewSecProps.appOverviewScreenPara}</p>
                    </div>
                  </div>
                </div>
              )
            }
          )}
        </div>
      </div>
    </AppoverviewsecStyles>
  )
}

export default Appoverviewsec
