import React from 'react'
import { images } from 'config/images'

export const FillInRxProps = {
  bannerProps: {
    heading: 'Fill In Rx',
    bannerPara: 'a pharmacy staffing solution',
    BannerAlt: 'Fill In Rx Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        Over the past five years, pharmacy stores in the US have seen YoY growth
        of 2.6%. This has led to an escalated demand for pharmacists. Employment
        agencies have not been successful in meeting these demands. When it
        comes to finding quick shift coverage, it takes them days and sometimes
        weeks. Also, they overcharge the pharmacy owners and underpay pharmacist
        for their work. <br /> <br /> Fill-in-Rx, wanted to build a marketplace
        that connects pharmacies and pharmacists. I wanted to fill the gap
        between pharmacies who are looking for shift coverage and pharmacist who
        are looking for job opportunities. It also helps Pharmacists to pick up
        additional shifts in their free time.
      </>
    ),
  },
  InteractiveappProps: {
    heading: 'Agile way to deliver the product',
    topPara:
      'The primary goal of Fill-In-Rx was to create a simple tool that would help pharmacies find staff on-demand as well as for the near future needs. We had to make sure that user experience is designed in a way that Pharmacy owners can easily approach multiple candidates and choose the best suitable one without having a call or in person meeting. Thus involving users and getting their feedback was very important.',
    bottomPara: (
      <>
        We followed agile development method to build the platform with monthly
        sprint approach so that clients can get feedback from real pharmacy
        owners and pharmacists on what is built. The development process in
        agile started with the most basic set of deliverables, followed by
        planning, implementing and testing the next set of features in
        subsequent iterations thus increasing the agility of the development
        team.
      </>
    ),
    mainImgcaption: 'Agile methodology for Product development',
  },
  casereSultsecProps: {
    heading: 'Result',
    RightSubHead:
      'App connecting pharmacies and pharmacists looking for coverage.',
    maincontent: (
      <>
        We developed a native iOS and native Android application which enabled
        the pharmacy managers/owners to hire the right pharmacist and enabled
        pharmacist to take-up posted shifts. Backend was built on highly
        scalable NodeJS server to deliver best in class performance to the app.{' '}
        <br />
        <br /> The application streamlined the process for pharmacy
        owners/managers looking for the right pharmacist to cover the shift with
        a few taps on their mobile phones.
      </>
    ),
    imgURL2x: images.fillInRxAppScreen2x,
    imgURL: images.fillInRxAppScreen,
  },
  appOverviewSecProps: {
    heading: 'Pharmacists on your fingertips',
    appOverviewSecDetails: [
      {
        appOverviewScreenTitle: 'Post a Job',
        appOverviewScreenPara:
          'It all starts with pharmacy owner posting a job to get the shift coverage.',
      },
      {
        appOverviewScreenTitle: 'Review applicants profiles',
        appOverviewScreenPara:
          'Once the shift is posted by the pharmacy owners, pharmacists receive an instant notification on their smartphones allowing them to apply without delay.',
      },
      {
        appOverviewScreenTitle: 'Get notified in real time',
        appOverviewScreenPara:
          'The users will get instant notifications on their smartphone once a pharmacist accepts the shift. The pharmacy owners will be able to see their name, licence, rating, and photo.',
      },
      {
        appOverviewScreenTitle: 'Leave ratings and reviews',
        appOverviewScreenPara:
          'At the end of each shift, pharmacy owners have to do the star ratings and give feedback to ensure that other pharmacy owners get access to the most reliable pharmacists on the market.',
      },
    ],
  },
  getHiredSecProps: {
    heading: 'Get Hired in few taps',
    appOverviewSecDetails: [
      {
        appOverviewScreenTitle: 'Set Preferences',
        appOverviewScreenPara:
          'Fill In Rx automatically matches pharmacists to jobs based on their availability, knowledge of specific prescription processing software and additional languages, as well as their preferred wage.',
      },
      {
        appOverviewScreenTitle: 'Be Your Own Boss',
        appOverviewScreenPara:
          'Pharmacists will be able to set the availability status for work and hourly rates in the application and apply to only job that pleases them.',
      },
      {
        appOverviewScreenTitle: 'Get reminded for shift',
        appOverviewScreenPara:
          'The app provides reminders to the users before the shifts. We have done the integration of google calendar in the application.',
      },
      {
        appOverviewScreenTitle: 'Current affairs in Pharma industry',
        appOverviewScreenPara:
          'Pharmacists can stay up-to-date with handpicked news and articles, best practices, treatment guidelines, latest research and case studies in the app. The pharmacy owners will get tips on ways to improve their business.',
      },
    ],
  },
  casecontentfullwProps: {
    heading: 'Intelligent Matchmaking Algorithm',
    para: (
      <>
        The efficiency or success of any staffing platform is directly affected
        by how precisely it matches job seekers and providers. Considering this,
        Simform designed an intelligent matchmaking algorithm which matches
        people according to their skills, experiences and availability. To make
        sure that server processes these matchmaking requests efficiently and
        effectively, we utilized Node server which is single threaded server
        which in background uses multiple threads to execute asynchronous code.
      </>
    ),
    bottomPara:
      'Whenever a pharmacist create a profile, it is evaluated based on the provided details and given a score. Same process is incorporated for the job posting done by the pharmacist. Based on these scores, pharmacists are matched with the relevant job posting, saving time and accelerating the hiring process. ',
  },
  ReliabilityinstancesProps: {
    trackheading: 'Crowd testing approach for the final quality check',
    ReliabilityinstancesDetails: [
      {
        trackbottomPara:
          'With the help of Applause - crowd testing platform, we achieved superior quality and user experience before going live. The focus on the testing the app in real world with real users and devices, helped developers gain valuable insights to do tweaks in the application. It further helped in improving the functionality, usability, compatibility and performance of the application. ',
      },
    ],
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'Waypoint',
        para: 'engaging users with a digital platform at live music festivals',
        className: 'pastel-blue',
        link: '/way-point-engagement-technology/',
      },
      {
        title: 'ONA Dating',
        para: 'enhance your dating experience with an online ‘Matchmaker’',
        className: 'pale-peach',
        link: 'case-studies/ona-dating/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
